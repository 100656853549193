import React from 'react';
import { MainRequirement } from './Requirements/MainRequirements';
import { SDKFeatures, SDKYesNoFeatures } from './Requirements/SDKRequirements';
import { SDKInfo } from '../QAEventListener';
import { useHistory } from 'react-router-dom';
import ActionLink, { ActionLinkButton } from '../../../../Footer/ActionLink';
import { PreviewOptions } from '../../../Preview/PreviewRoute';
import SubmissionSubmitManager from './SubmissionSubmitManager';
import { PreviewSubmission } from '../../../Preview/preview-graphql';

export interface QAResult {
  unfulfilledMainRequirements: MainRequirement[];
  SDKImplemented: boolean;
  SDKFeatures: (SDKFeatures | SDKYesNoFeatures)[];
  failedSDKFeatures: (SDKFeatures | SDKYesNoFeatures)[];
  SDKInfo?: SDKInfo;
  adScenario: string;
}

const StandAloneSubmitManager: React.FC = () => {
  const history = useHistory();
  const getPrimaryButton = (): ActionLinkButton => {
    return {
      title: 'Quit QA tool',
      callback: () => {
        history.push('/qatool');
      },
    };
  };

  return <ActionLink primaryButton={getPrimaryButton()} secondaryButton={undefined} />;
};

interface SubmitManagerProps {
  submission?: PreviewSubmission;
  previewOptions: PreviewOptions;
  onMobile: boolean;
}

const SubmitManager: React.FC<SubmitManagerProps> = ({ submission, previewOptions, onMobile }) => {
  if (onMobile) {
    return null;
  }
  if (submission) {
    return <SubmissionSubmitManager submission={submission} previewOptions={previewOptions} />;
  } else {
    return <StandAloneSubmitManager />;
  }
};

export default SubmitManager;
