import React, { useCallback, useContext, useEffect, useState } from 'react';
import GameBaseFormFilesAndOptions from '../GameBaseForm/GameBaseFormFilesAndOptions';
import ActionLink from '../../../../Footer/ActionLink';
import { QANextStep } from '../../../Preview/PreviewRoute';
import { submissionToUpdateGraphQLInput } from '../../SubmitGame/submission-helpers';
import { toast } from 'react-toastify';
import { updateSubmission } from '../Mutations/update-submission-mutation';
import { ApolloError } from '@apollo/client';
import { SubmissionContext } from '../Context/SubmissionProvider';
import GameBaseFormName from '../GameBaseForm/GameBaseFormName';
import GameJamConfirmation from '../GameJamConfirmation';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { GameJamLink, getGameJamLink } from '../gamejamHelper';

interface BaseInfoStepProps {
  goToQA(nextStep: QANextStep): void;
  goToDetails(): void;
  onError: (error?: ApolloError) => void;
}

// Dev land on this step only if the submission is in DRAFT status
// Next step is either:
// - QA if need for QA (or save & QA if modified)
// - Continue to next step if no need for QA (or save & continue to next step)
// - Simply "Save changes" for gamejam games
const BaseInfoStep: React.FC<BaseInfoStepProps> = ({ goToQA, goToDetails, onError }) => {
  const { baseForm, baseFormProblems, submission, setAttemptedToSave, setIsModified, modified, revalidateByQA, detailsForm } =
    useContext(SubmissionContext);

  const [isSaving, setIsSaving] = useState(false);
  const [buttonInfo, setButtonInfo] = useState<{ title: string; callback: () => void } | null>(null);
  const [id, setId] = useState<string | undefined>();
  const [slug, setSlug] = useState<string | undefined>();

  const submit = useCallback(
    async (qaNext: boolean) => {
      if (baseFormProblems && baseFormProblems.length > 0) {
        toast.error('Please check if all the mandatory fields marked with * are filled before continuing', { autoClose: 8000 });
        setAttemptedToSave(true);
        return;
      }

      setIsSaving(true);

      try {
        // If submission already have details filled (as category) we need to send along the details form to prevent overwriting with empty values
        const updateInput = submissionToUpdateGraphQLInput(submission!.id, baseForm!, submission?.categoryId ? detailsForm : undefined);
        const res = await toast.promise(updateSubmission(updateInput), {
          pending: 'Updating submission...',
          success: 'Submission Updated!',
          error: 'Error updating submission',
        });
        const { id, slug } = res.data!.developerSubmissionUpdate;
        setId(id);
        setSlug(slug);
        onError();
        setIsSaving(false);
        setIsModified(false, () => {
          if (baseForm.isGameJam) {
            return;
          }
          if (qaNext) {
            goToQA(QANextStep.UPDATE_COMPLETE);
          } else {
            goToDetails();
          }
        });
      } catch (err) {
        onError(err as ApolloError);
        setIsSaving(false);
      }
    },
    [baseFormProblems, setAttemptedToSave, submission, baseForm, detailsForm, onError, setIsModified, goToQA, goToDetails],
  );

  const getButtonInfo = useCallback((): { title: string; callback: () => void } | null => {
    if (!submission) {
      return null; // This screen should not be visible if there is no submission
    }

    if (baseForm.isGameJam) {
      if (!modified) {
        return null;
      }
      return {
        title: 'Save changes',
        callback: () => {
          submit(false);
        },
      };
    }

    if (modified) {
      if (revalidateByQA) {
        return {
          title: 'Save and go to QA',
          callback: () => {
            submit(true);
          },
        };
      } else {
        return {
          title: 'Save and continue next step',
          callback: () => {
            submit(false);
          },
        };
      }
    }
    if (revalidateByQA) {
      return {
        title: 'Go to QA',
        callback: () => {
          goToQA(QANextStep.UPDATE_COMPLETE);
        },
      };
    } else {
      return {
        title: 'Continue to next step',
        callback: goToDetails,
      };
    }
  }, [baseForm.isGameJam, goToDetails, goToQA, modified, revalidateByQA, submission, submit]);

  useEffect(() => {
    setButtonInfo(getButtonInfo());
  }, [baseFormProblems, baseForm, getButtonInfo, goToQA, modified, onError, setAttemptedToSave, setIsModified, submission]);

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  const renderGameJamSubmissionComplete = useCallback(() => {
    if (!id || !slug) {
      return null;
    }
    return (
      <GameJamConfirmation
        submissionId={id}
        goToSubmission={() => {
          refresh();
        }}
      />
    );
  }, [refresh, id, slug]);

  if (!submission) {
    return null; // This screen should not be visible if there is no submission
  }

  // id and slug are set when the submission is saved
  if (id && slug && baseForm.isGameJam) {
    return renderGameJamSubmissionComplete();
  }

  const link = getGameJamLink(submission.id);

  return (
    <>
      {submission.isGameJam && (
        <StyledBodyText variant="h3" sx={{ marginY: 1.25, marginX: 1.25 }} color="white80">
          Your submission link:{' '}
          <GameJamLink href={link} target="_blank">
            {link}
          </GameJamLink>
        </StyledBodyText>
      )}
      <GameBaseFormName />
      <GameBaseFormFilesAndOptions showGameJamInForm={true} />
      {buttonInfo && (
        <ActionLink
          primaryButton={{
            title: buttonInfo.title,
            callback: buttonInfo.callback,
          }}
          primaryButtonDisabled={isSaving || baseForm.uploadInProgress}
        />
      )}
    </>
  );
};

export default BaseInfoStep;
