import { DEFAULT_LOCALE, LOCALE, TranslatedEntry } from './i18n';
import slugify from './slug';
import { config } from './config';

function gamePrefixByLocale(locale: LOCALE) {
  switch (locale) {
    case 'nl_NL':
      return 'spel';
    case 'it_IT':
      return 'gioco';
    case 'es_ES':
      return 'juego';
    case 'fr_FR':
      return 'jeu';
    case 'pt_BR':
      return 'jogos';
    case 'ru_RU':
      return 'igra';
    case 'de_DE':
      return 'spiele';
    case 'fi_FI':
      return 'peli';
    case 'nb_NO':
      return 'spill';
    case 'pl_PL':
      return 'gra';
    case 'ro_RO':
      return 'joc';
    case 'sv_SE':
      return 'spel';
    case 'uk_UA':
      return 'gra';
    case 'el_GR':
      return 'paixnidi';
    case 'da_DK':
      return 'spil';
    case 'cs_CZ':
      return 'hra';
    case 'hu_HU':
      return 'jatek';
    case 'tr_TR':
      return 'oyun';
    case 'vi_VN':
      return 'trò-chơi';
    case 'th_TH':
      return 'เกม';
    case 'ko_KR':
      return '게임';
    case 'en_US':
    case 'id_ID':
    case 'ar_SA':
    default:
      return 'game';
  }
}

function tagPrefixByLocale(locale: LOCALE) {
  switch (locale) {
    case 'nl_NL':
      return 'tag';
    case 'it_IT':
      return 'tipo';
    case 'es_ES':
    case 'el_GR':
    case 'tr_TR':
      return 'e';
    case 'th_TH':
      return 'แท็ก';
    default:
      return 't';
  }
}

function categoryPrefixByLocale(locale: LOCALE) {
  switch (locale) {
    case 'nl_NL':
      return 'categorie';
    case 'it_IT':
      return 'categoria';
    case 'es_ES':
      return 'categoria';
    case 'th_TH':
      return 'หมวดหมู่';
    case 'id_ID':
    case 'el_GR':
    case 'tr_TR':
      return 'k';
    default:
      return 'c';
  }
}

function getBust() {
  return Math.random().toString(36).substr(2, 5);
}

interface GameUrlOptions {
  cacheBust?: boolean;
  testAds?: boolean;
  sdkDebug?: boolean;
  noWaitTime?: boolean;
  isKids?: boolean;
  secretSkipPreroll?: boolean;
  ruffleDebug?: boolean;
  forceMobile?: boolean;
}

interface TagCategoryUrlOptions {
  cacheBust?: boolean;
}

function getBaseUrl(locale: LOCALE) {
  return (config.domains[locale] || config.domains[DEFAULT_LOCALE]).base;
}

export function getGameUrl(gameSlug: string, options: GameUrlOptions, locale: LOCALE = 'en_US') {
  const { cacheBust, testAds, sdkDebug, noWaitTime, isKids = false, secretSkipPreroll, ruffleDebug = false, forceMobile } = options;
  let url;
  if (!isKids) {
    const base = getBaseUrl(locale);
    const gamePrefix = gamePrefixByLocale(locale);
    url = new URL(`https://${base}/${gamePrefix}/${gameSlug}`);
  } else {
    url = new URL(`https://${config.kidSiteDomain.base}/game/${gameSlug}`);
  }
  const { searchParams } = url;
  if (cacheBust) {
    const bustStr = getBust();
    searchParams.set('bypassCache', bustStr);
  }
  if (testAds) {
    searchParams.set('testAds', 'true');
  }
  if (sdkDebug) {
    searchParams.set('sdk_debug', 'true');
  }
  if (noWaitTime) {
    searchParams.set('noWaitTime', 'true');
  }
  if (secretSkipPreroll) {
    searchParams.set('secretSkipPreroll', 'true');
  }
  if (ruffleDebug) {
    searchParams.set('ruffle_debug', 'true');
  }
  if (forceMobile) {
    searchParams.set('forceMobile', 'true');
  }
  return decodeURIComponent(url.toString());
}

export function getTagUrl(tagSlugTrl: TranslatedEntry[], options: TagCategoryUrlOptions, locale: LOCALE = 'en_US') {
  const base = getBaseUrl(locale);
  const tagPrefix = tagPrefixByLocale(locale);
  const tagSlug = (tagSlugTrl.find((slugTrl) => slugTrl.locale === locale) || {}).text;
  const url = new URL(`https://${base}/${tagPrefix}/${tagSlug}`);
  const { searchParams } = url;

  if (options.cacheBust) {
    const bustStr = getBust();
    searchParams.set('cacheBust', bustStr);
  }
  return url.toString();
}

export function getCategoryUrl(categorySlugTrl: TranslatedEntry[], options: TagCategoryUrlOptions, locale: LOCALE = 'en_US') {
  const base = getBaseUrl(locale);
  const categoryPrefix = categoryPrefixByLocale(locale);
  const categorySlug = (categorySlugTrl.find((slugTrl) => slugTrl.locale === locale) || {}).text;
  const url = new URL(`https://${base}/${categoryPrefix}/${categorySlug}`);
  const { searchParams } = url;

  if (options.cacheBust) {
    const bustStr = getBust();
    searchParams.set('cacheBust', bustStr);
  }
  return url.toString();
}

function isPrefixedUrl<T>(url: string, prefix: string, locale: LOCALE, type: T) {
  const base = getBaseUrl(locale);

  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.hostname !== base) {
      return false;
    }
    const pathnamePrefix = `/${prefix}/`;
    if (!parsedUrl.pathname.startsWith(pathnamePrefix)) {
      return false;
    }
    const slug = parsedUrl.pathname.substring(pathnamePrefix.length);
    if (slug !== slugify(slug)) {
      return false;
    }
    return { slug, type };
  } catch (e) {
    return null;
  }
}

export function isTagUrl(url: string, locale: LOCALE) {
  const prefix = tagPrefixByLocale(locale);
  return isPrefixedUrl<'tag'>(url, prefix, locale, 'tag');
}

export function isCategoryUrl(url: string, locale: LOCALE) {
  const prefix = categoryPrefixByLocale(locale);
  return isPrefixedUrl<'category'>(url, prefix, locale, 'category');
}

export function isGameUrl(url: string, locale: LOCALE) {
  const prefix = gamePrefixByLocale(locale);
  return isPrefixedUrl<'game'>(url, prefix, locale, 'game');
}
